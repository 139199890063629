import _ from 'lodash';
import {IThankYouPageSettings} from '../../types/app.types';

export function getThankYouPageSettingsFromStyles(rawStyles: IThankYouPageSettings): IThankYouPageSettings {
  const defaultStyles: IThankYouPageSettings = {
    booleans: {},
  };

  return _.merge({}, defaultStyles, rawStyles);
}

import {IThankYouPageSettings} from '../../types/app.types';

export class StyleParamsStore {
  private readonly settings: IThankYouPageSettings;

  constructor({settings}: {settings: IThankYouPageSettings}) {
    this.settings = settings;
    //todo itay
    void this.settings;
  }
  public toProps() {
    return {};
  }
}
